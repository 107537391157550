/* HEADER STYLES */
$margin_y: 25px;

.homepage .header-container {
    width: 100%;
    max-width: 100vw;
    height: auto;
    background: color('header-bg-hidden', '', '', $colors-header);

    &.scrolled {
        background: color('header-bg', '', '', $colors-header);
        transition: background 0.2s ease;
    }

    .header-nav-main {
            background: color('header-bg-hidden', '', '', $colors-header);
            transition: 1s;

            @media(max-width: 992px) {
               /* background: color('header-bg-transparent', '', '', $colors-header);*/
            }
    }
}

.header-container {
    max-width: 100vw;
    color: color('header-nav', '', '', $colors-header);
    height: auto;
    background: color('header-container-bg', '', '', $colors-header);

    &.fixed-top {
        backface-visibility: hidden;
    }

    &.scrolled {
        .img-fluid {
            transition: all ease 1s;
        }
    }

    .header-nav-top {
        background: color('header-top-bg', '', '', $colors-header);
        border-bottom: 1px solid color('header-border', '', '', $colors-header);
    }

    #currency-dropdown {
        color: color('header-top-text', '', '', $colors-header);
        background-color: color('header-top-bg', '', '', $colors-header);

    }

    .header-nav-main {
        .company-brand {
            text-align: left;

            img {
                margin-top: $margin_y;
                margin-bottom: $margin_y;
                height: 79px;
            }
        }

        .header-nav {
            position: relative;
            bottom: 0;
            font-size: 17px;
            font-family: font(sub);
            font-weight: 500;
            margin-bottom: 0;

            .nav-item {
                position: relative;
                border-bottom: 2px solid transparent;

                &.active,
                &:hover {
                    border-bottom: 2px solid color('header-active', '', '', $colors-header);
                    transition: 0.2s ease;
                }

                &:hover {
                    .dropdown-menu:not(.property-dropdown) {
                        display: block;
                        position: absolute;
                        left: auto;
                        right: 0;
                        border: none;
                    }
                }

                .dropdown-menu {
                    z-index: 1030;
                    line-height: 1.5;
                    background: #f5f3f2;
                    color: #777777;
                    @include box-drop-shadow();
                    @include border-radius(0);

                    li {
                        &:not(:last-of-type) {
                            border-bottom: 1px solid rgb(207, 207, 207);
                        }

                        margin-left: 10px;
                        margin-right: 10px;
                        padding-bottom: 10px;
                        padding-top: 10px;
                    }

                    .dropdown-item {
                        font-size: 13px;
                        font-family: font(main);
                        padding: 0 !important;
                        text-align: left;

                        &.active,
                        &:hover {
                            background: transparent;
                            color: color('header-nav-dropdown-li-hover', '', '', $colors-header);
                        }
                    }
                }

                ul {
                    &.navigation-column {
                        display: inline-block;
                        text-align: center;
                        width: 49%;
                        vertical-align: top;

                        li {
                            padding-bottom: 10px;
                            padding-top: 10px;

                            a {
                                width: 100%;
                                white-space: normal;
                            }
                        }
                    }
                }
            }

            .nav-link {
                padding: 0 3px;
                line-height: 2;
            }
        }
    }
}

/* Header social icons */
.social-container {
    position: relative !important;
    padding: 0;

    .social-popup,
    .social-popup-trigger {
        display: none;
    }

    .social-link {
        text-align: center;
        padding-left: 0;
        border: none;
        line-height: 40px;

        &.icon-only {
            font-size: 15px;
            padding-right: 15px;
            line-height: 40px;
            display: inline-block;

            i {
                font-size: 13px;
            }

            i.fa-facebook {
                font-size: 15px;
            }

            i.fa-google-plus {
                font-size: 15px;
            }
        }

        &:not(:first-of-type) {
            padding-left: 15px;
        }

        &:not(:last-of-type) {
            border-right: 1px solid color('border', 'light') !important;
        }

        &:first-of-type {
            padding-right: 15px;
        }

        i {
            vertical-align: middle;
            color: color('header-i', '', '', $colors-header);
            line-height: 30px;
        }
    }
}

/* HEADER CONTACT */
.contact-info {
    text-align: right;
    display: inline-block;

    a {
        line-height: 40px;
        font-family: font(sub);
        display: inline-block;
        color: color('header-top-text', '', '', $colors-header);
        padding: 0 10px 0 0;
        font-size: 14px;

        &:first-of-type {
            padding-left: 0;
        }

        &:hover {
            text-decoration: none;
        }
    }

    i {
        color: color('header-i', '', '', $colors-header);
        line-height: 30px;
        padding-right: 5px;

        &.fa-phone {
            font-size: 14px;
        }

        &.fa-skype {
            font-size: 1.4rem;
        }

        &.fa-envelope {
            font-size: 14px;
            padding-left: 7px;
        }
    }

    .nav-contact-text {
        display: none;

        @media (min-width: 1024px) {
            display: inline;
        }
    }

    &.text-muted {
        display: inline;
        margin-left: 1px;
    }

    label {
        display: inline;
        margin: 0;
    }
}

.lang-wrap {
    margin-right: 5px;
    position: relative;
    top: 5px;

    .dropdown-menu {
        top: 10px !important;
        background-color: color('language-dropdown-bg', '', '', $colors-header);
        border: none;
        right: -5px;
        left: auto !important;
        border-radius: 0 0 0.25rem 0.25rem;
        @include box-drop-shadow();

        @media (max-width: 1024px) {
            right: auto;
        }

        .dropdown-item {
            &:hover {
                background-color: color('language-dropdown-li-hover', '', '', $colors-header);
            }
        }
    }

    a {
        line-height: 30px;
        text-decoration: none;
        text-transform: capitalize;
        font-size: 13px;
        font-family: font(sub);
        font-weight: 500;
        color: color('header-top-text', '', '', $colors-header);
        @include transition(all .3 linear);

        &.dropdown-toggle::after {
            border: 0 !important;
            content: "\f078" !important;
            vertical-align: 0 !important;
            margin-left: 5px !important;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 10px;
        }

        .lang-label {
            @media(max-width: $media_md) {
                display: none;
            }
        }

        img {
            width: 21px;
            margin-right: 10px;
            position: relative;
            top: -2px;
        }

        /*&.active {
            color: color('header-active', '', '', $colors-header);
        }*/
    }
}

/* BUTTON HAMBURGER */
.btn-menu {
    margin-left: auto;
    display: inline-block;
    padding: $margin_y+10;
    color: color('btn-hamburger', '', '', $colors-header);

    &:hover {
        text-decoration: none;
    }

    .menu-text {
        text-transform: uppercase;
        font-size: 1rem;
        font-family: font(sub);
        font-weight: 600;
        line-height: 1;
        margin-left: 10px;
    }

    span {
        display: block;
        background-color: color('btn-hamburger', '', '', $colors-header);
        width: 20px;
        height: 2px;
        margin-bottom: 4px;
    }

    span:last-child {
        margin-bottom: 0;
    }
}

.navigation-wrapper {
    align-items: center;
    justify-content: flex-end;
}

/* MEDIA QUERIES */
@media (max-width: 1700px) {
    .contact-info {
        span {
            line-height: 30px;
            padding: 0 8px;

            i {
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: 1600px) {
    .header-container {
        .social-container {
            .social-link {
                width: 40px;
                line-height: 30px;
            }
        }
    }

    .contact-info {
        span {
            line-height: 30px;
            padding: 0 8px;

            i {
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: 1300px) {
    .contact-info {
        span {
            label {
                display: none;
            }
        }
    }
}

@media(max-width: 1040px) {
    .contact-info {
        span {
            padding: 0 4px;
            border: 0 !important;
            font-size: 1.1rem;
        }
    }
}

@media (max-width: $media_xl) {
    .contact-info {
        .text-muted {
            display: none;
        }
    }
}

@media (max-width: $media_lg) {
    .btn-menu {
        margin-top: 5px;

        .menu-text {
            display: none !important;
        }
    }

    .mobile-lang {
        font-size: 1rem;
        height: 38px;
    }

    .mobile-book {
        position: relative;
        top: 45px;
        font-size: 1.2rem;
        background-color: color('header-bg', '', '', $colors-header);

        i {
            color: color('white');
        }
    }
}

body:not(#homepage) {
    .header-container {
        .header-nav-main {
            border-bottom: 1px solid #d4d4d4;
        }
    }
}