.banners-wrapper {
    padding-top: 50px;

    &:last-child {
        padding-bottom: 50px;
    }

    @media(max-width: 768px) {
        padding-top: 0;
        padding-bottom: 0;
    }

    .banner-wrapper {
        display: flex;
        color: color('white');
        text-decoration: none;
        position: relative;

        &:hover {
            &::after {
                content: " ";
                position: absolute;
                top: 0;
                left: 15px;
                right: 15px;
                bottom: 0;
                background: #000;
                opacity: 0.3;
            }

            .banner-content-title + .banner-content-text {
                visibility: visible;
                animation: moveToRight 1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 10ms;
            }

            .banner-content-title {
                width: 300px !important;

                h2 {
                    text-align: right;
                }
            }
        }

        @media(max-width: 768px) {
            margin-top: 50px;
        }

        a {
            width: 100%;
            display: block;
            height: 300px;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;

            .banner-content-wrapper {
                position: absolute;
                bottom: 0;
                padding: 30px 30px 30px 0;
                font-family: font(main);
                z-index: 2;

                .banner-content-title {
                    width: 100%;
                    transition: width 1s ease;

                    h2 {
                        background-color: rgba(30, 36, 41, .8);
                        font-size: 19px;
                        font-weight: 500;
                        text-transform: uppercase;
                        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
                        padding: 10px 25px;
                        text-align: center;
                    }

                    @media(max-width: 768px) {
                        font-size: 22px;
                    }
                }

                .banner-content-text {
                    visibility: hidden;
                    width: 176px;
                    text-align: right;
                    color: black;
                    background-color: #FFF;
                    padding: 10px 20px;

                    p {
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 1;
                        opacity: 1;
                        position: relative;
                        text-decoration: none;

                        &::after {
                            content: "\f105";
                            margin-left: 10px;
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            font-size: 15px;
                            position: relative;
                            top: 1px;
                        }
                    }
                }
            }
        }
    }
}

@keyframes moveToRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(124px);
    }
}

