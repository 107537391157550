.homepage-banners {
    background-color: #f5f5f5;
    padding-top: 60px;
    padding-bottom: 60px;

    @media (max-width: 992px) {
        padding-bottom: 40px;
    }

    .banner-item {
        text-align: center;
        padding: 0;

        @media (max-width: 992px) {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            border-radius: 5px;
            position: relative;
            display: inline-block;
            padding: 20px 10px;
            width: 100%;

            &:hover {
                box-shadow: inset 0 0 10px rgba(0, 0, 0, .2);
                text-decoration: none;
            }
        }

        .banners-title {
            font-family: font(main);
            font-size: 15px;
            font-weight: 400;
            color: #313b42;
            // text-transform: uppercase;
            width: 100%;
            // position: absolute;
            // bottom: 20px;
            // left: 20px;
            // text-align: center;
            margin-top: 15px;
        }

        &:last-of-type {
            a {
                width: auto;
                padding: 20px;
                margin: 0 auto;

                .banners-title {
                    font-size: 15px;
                    font-weight: 400;
                    color: #313b42;
                    width: 100%;
                    margin-top: 15px;
                }
            }
        }
    }
}