.featured-text-section {
    background-position: center;
    background-size: cover;
    padding: 0 !important;

    .section-content {
        background: rgba(20, 61, 90, 0.6);
        padding: 100px 50px;

        @media(max-width: $media_md) {
            padding: 50px;
        }

        .section-title {
            font-size: 30px;
            color: #fff;
        }

        .section-separator {
            background: color('primary');
            color: color('primary');
            margin: 20px auto !important;
            height: 2px;
        }

        .section-text {
            text-align: left !important;
            clear: both;
            margin-top: 0 !important;

            p {
                font-size: 15px;
                color: #fff;
                width: 50%;

                @media(max-width: $media_xl) {
                    width: 100%;
                }
            }
        }

        .section-btn {
            font-weight: 400;
            border-radius: 0;
        }
    }
}